<template>
  <div>
    <div class="flex flex-col lg:block">
      <div class="order-last md:order-2 lg:relative">
        <HomeHeroSlider class="md:mt-auto" />
        <Searchbar
          class="hidden p-4 md:bottom-0 md:bg-teal/50 lg:absolute lg:block"
        />
      </div>

      <CardGroup
        id="homeCardGroup"
        cols="4"
        :limit="8"
        :steps="8"
        :mobile-slider="true"
        :title="offers.title"
        :items="offers.top_offers"
        :button="offers.load_more_label"
        :settings="{ autoplay: 5000 }"
        class="order-first md:order-3"
      >
        <template #header>
          <div class="flex justify-center">
            <G7Button
              color="orange"
              class="my-4 hidden text-center md:block"
              :label="offers.title"
              @click="scrollToIdPosition('homeCardGroup', 0, 'smooth')"
            />
          </div>
        </template>
        <template #default="{ item }">
          <CruiseOffer :item="item" />
        </template>
      </CardGroup>
      <Searchbar
        class="bg-[url('/cruise-finder_bg.jpg')] bg-top p-4 md:order-first md:bg-none lg:hidden"
      />
    </div>
    <NewsletterSubscription class="hidden lg:flex" />
    <CardGroup
      v-slot="{ item }"
      :limit-mobile="3"
      :title="specialOffers.title"
      :items="specialOffers.teasers"
    >
      <ImgCard :item="item" />
    </CardGroup>
    <HomeCruiselineLogos class="hidden lg:inline" />
    <CardGroup
      v-slot="{ item }"
      :mobile-slider="true"
      class="my-8 lg:my-auto"
      title="Unser Kreuzfahrt Magazin"
      :items="magazin.teasers"
    >
      <Infocard class="mx-8 md:mx-0" :item="item" />
    </CardGroup>
  </div>
</template>

<script lang="ts" setup>
import type { DefaultLayout } from "~~/types/layout";
import type {
  MagazinArticlesResponse,
  SpecialOffersResponse,
  TopOffersResponse,
} from "~~/types/response";

const layoutPromise = useGo7seasApi<DefaultLayout>(
  apiUrl("/catalog/homepages/go7seas")
);

const specialOffersPromise = useGo7seasApi<SpecialOffersResponse>(
  apiUrl("/catalog/homepages/go7seas/special-offers")
);

const magazinPromise = useGo7seasApi<MagazinArticlesResponse>(
  apiUrl("/catalog/homepages/go7seas/magazin/articles?offset=0&limit=3")
);

const offersPromise = useGo7seasApi<TopOffersResponse>(
  apiUrl("/catalog/homepages/go7seas/top-offers?offset=0&limit=8")
);

const responses = await Promise.all([
  layoutPromise,
  useDiaShow().fetch(apiUrl("/catalog/homepages/go7seas/diashow")),
  specialOffersPromise,
  magazinPromise,
  offersPromise,
]);

const layout = responses[0].data;
const specialOffers = responses[2].data;
const magazin = responses[3].data;
const offers = responses[4].data;

updateDefaultLayout(layout.value);
</script>
